@import 'css/normalize.css';

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eee;
  font-family: Courier, Monaco, monospace;
  color: rgba(0,0,0,0.6);
  font-size: 16px;
  line-height: 1.5em;

  background: #d9a7c7;
  background: -webkit-linear-gradient(to right, #fffcdc, #d9a7c7);
  background: linear-gradient(to right, #fffcdc, #d9a7c7);
}

.site-header {
  width: 850px;
  margin: 30px 0 40px 0;
}

.site-title {
  color: #fff;
  font-size: 28px;
  text-decoration: none;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.page-content {
  width: 850px;
  background: rgba(255,255,255,.6);
  padding: 15px 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.post-list-heading {
  margin: 0 0 15px;
  color: rgba(0,0,0,.3);
}

.post-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.post-meta {
  font-size: 12px;
  color: rgba(0,0,0,.2);
}

.post-list h3 {
  margin: 0px 0 25px;
  font-weight: normal;
  font-size: 20px;
}

.post-list a {
  text-decoration: none;
  color: #f16581;
}

.post-list li:nth-child(8n) a { color: #ff9326; }
.post-list li:nth-child(8n-1) a { color: #97bf40; }
.post-list li:nth-child(8n-2) a { color: #f5a3bf; }
.post-list li:nth-child(8n-3) a { color: #b1d8dc; }
.post-list li:nth-child(8n-4) a { color: #ff69b4; }
.post-list li:nth-child(8n-5) a { color: #dda0dd; }
.post-list li:nth-child(8n-6) a { color: #40e0d0; }
.post-list li:nth-child(8n-7) a { color: #f16581; }

.post-title {
  color: #ff69b4;
  font-size: 28px;
  line-height: 1.3em;
}

.post img {
  background: rgba(255,255,255,.8);
  padding: 15px;
  display: block;
  margin:  15px auto;
  max-width: 700px;
}

.post-tags {
  margin-top: 50px;
}

.post-tags ul {
  list-style: none;
  display: flex;
}

.post-tags li {
  margin: 0 0 0 15px;
  background: rgba(255,255,255,0.7);
}

.post-tags li:hover {
  background: rgba(255,255,255,0.5);
}

.post-tags a {
  text-decoration: none;
  color: rgba(0,0,0,0.5);
  padding: 5px 10px;
  display: block;
}

.post-tags ul li a:hover {
  color: #fff;
}

.post-tags li:nth-child(8n) a { color: #ff9326; }
.post-tags li:nth-child(8n-1) a { color: #97bf40; }
.post-tags li:nth-child(8n-2) a { color: #f5a3bf; }
.post-tags li:nth-child(8n-3) a { color: #b1d8dc; }
.post-tags li:nth-child(8n-4) a { color: #ff69b4; }
.post-tags li:nth-child(8n-5) a { color: #dda0dd; }
.post-tags li:nth-child(8n-6) a { color: #40e0d0; }
.post-tags li:nth-child(8n-7) a { color: #f16581; }

.post-tags li:nth-child(8n):hover  { background: #ff9326; }
.post-tags li:nth-child(8n-1):hover  { background: #97bf40; }
.post-tags li:nth-child(8n-2):hover  { background: #f5a3bf; }
.post-tags li:nth-child(8n-3):hover  { background: #b1d8dc; }
.post-tags li:nth-child(8n-4):hover  { background: #ff69b4; }
.post-tags li:nth-child(8n-5):hover  { background: #dda0dd; }
.post-tags li:nth-child(8n-6):hover  { background: #40e0d0; }
.post-tags li:nth-child(8n-7):hover  { background: #f16581; }


.rss-subscribe {
  display: none;
}

.site-footer {
  padding: 40px;
  color: rgba(0,0,0,.3);
  font-size: 12px;
}

